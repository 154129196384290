(function () {
        angular.module('informaApp')
            .component('ganttChartElement', {
                templateUrl: 'components/summary-view/summary-elements/elements/gantt-chart-element/template.html',
                controller: GanttChartElement,
                bindings: {
                    data: '<',
                    settings: '<',
                    onReady: '<',
                    onStartRedraw: '<'
                }
            });

        function GanttChartElement(ObjectPropertyReader, ChartService) {
            this.$onChanges = () => {
                if (this.onStartRedraw) {
                    this.onStartRedraw();
                }

                this.chartSource = [];

                this.hiddenFilterItems = {
                    noData: true
                };

                const defaultFilter = {
                    phase1: true,
                    phase2: true,
                    phase3: true,
                    ndabla: true,
                    noData: true,
                    succeeded: true,
                    inProgress: true,
                    failed: true
                };

                const filter = ObjectPropertyReader.getPropertyOrDefault(this.settings, x => x.filter, defaultFilter);
                this.filter = _.merge(defaultFilter, filter);

                ChartService.ganttChart(this.data.drugIndicationIds.shown, this.data.filter.startDate, this.data.filter.endDate).then((response) => {
                    this.chartSource = response.data.data;

                    this.onReady();
                });
            }
        }
    }
)();